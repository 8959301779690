export class ValidationConstants
{
	private constructor()
	{
	}

	public static ItalianFiscalCode = class
	{
		private constructor()
		{
		}

		public static readonly CHAR_REGEXES: RegExp[] = [
			/[A-Z]/,
			/[A-Z]/,
			/[A-Z]/,
			/[A-Z]/,
			/[A-Z]/,
			/[A-Z]/,
			/[0-9LMNPQRSTUV]/,
			/[0-9LMNPQRSTUV]/,
			/[ABCDEHLMPRST]/,
			/[0-7LMNPQRST]/,
			/[0-9LMNPQRSTUV]/,
			/[A-Z]/,
			/[0-9LMNPQRSTUV]/,
			/[0-9LMNPQRSTUV]/,
			/[0-9LMNPQRSTUV]/,
			/[A-Z]/,
		]

		public static readonly MONTH_LETTERS: string[] = [
			"A", "B", "C", "D", "E", "H", "L", "M", "P", "R", "S", "T",
		]

		public static readonly DIGIT_REPLACEMENTS: string[] = [
			"L", "M", "N", "P", "Q", "R", "S", "T", "U", "V",
		]

		public static readonly SET_1: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
		public static readonly SET_2: string = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ"
		public static readonly ODD_SET: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
		public static readonly EVEN_SET: string = "BAKPLCQDREVOSFTGUHMINJWZYX"
	}
}
