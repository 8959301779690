import React from "react"
import {
	TextField,
} from "@mui/material"
import { Controller } from "react-hook-form"
import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"

const ITEM_HEIGHT = 48

interface MatesSelectProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,
	onChange?: (data: string) => string,
}
export class MatesSelect extends React.Component<MatesSelectProps, {}>
{
	static defaultProps = {
		disabled: false,
		required: false,
		variant: "standard",
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				defaultValue=""
				render={({ field }) => (
					<TextField
						{...field}
						select
						fullWidth
						required={this.props.required}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						label={this.props.label}
						variant={this.props.variant}
						{...commonErrorParameters(this.props.name, this.props.formState)}
						SelectProps={{
							MenuProps: {PaperProps: {sx: {maxHeight: ITEM_HEIGHT * 4.5}}},
							native: true,
						}}
						onChange={({ target: { value } }) => {
							const newValue = this.props.onChange?.(value) || value
							field.onChange(newValue)
							this.props.trigger(this.props.name)
						}}
					>
						<option aria-label="None" value="" />
						{this.props.children}
					</TextField>
				)}
			/>
		)
	}
}
