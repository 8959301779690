import React from "react"
import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"


interface MatesTextFieldProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,
	type?: "text"|"password",
	startAdornment?: React.ReactNode,
	endAdornment?: React.ReactNode,
	onChange?: (data: string) => string,
	onBlur?: (data: string) => string,
}
export class MatesTextField extends React.Component<MatesTextFieldProps, {}>
{
	static defaultProps = {
		required: false,
		variant: "standard",
		type: "text",
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				defaultValue=""
				render={({ field }) => (
					<TextField
						{...field}
						{...commonErrorParameters(this.props.name, this.props.formState)}
						fullWidth
						required={this.props.required}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						label={this.props.label}
						variant={this.props.variant}
						type={this.props.type}
						InputProps={{
							startAdornment: this.props.startAdornment,
							endAdornment: this.props.endAdornment,
						}}
						onChange={({ target: { value } }) => {
							const newValue = this.props.onChange?.(value) || value
							field.onChange(newValue)
							this.props.trigger(this.props.name)
						}}
						onBlur={({ target: { value } }) => {
							const newValue = this.props.onBlur?.(value) || value
							field.onChange(newValue)
							this.props.trigger(this.props.name)
						}}
					/>
				)}
			/>
		)
	}
}
