import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

const withUseFormHook = <T, >(Component: React.ComponentType<any>, yupSchema: any, defaultValues?: any,) => {
	return (props: any) => {
		const form = useForm<T>({
			resolver: yupResolver(yupSchema),
			defaultValues: defaultValues,
		})
		return <Component {...props} {...form} />
	}
}

export default withUseFormHook
