import { DateTime } from "luxon"

import { ValidationConstants } from "./constants"

import {
	ItalianFiscalCodeData,
	ItalianFiscalCodeResult,
	ItalianFiscalCodeSex,
} from "./models"

export class ItalianFiscalcodeValidator
{
	static readonly CONSTANTS = ValidationConstants.ItalianFiscalCode

	private constructor()
	{
	}

	public static validate = (fiscalCode: string, data: ItalianFiscalCodeData|null): ItalianFiscalCodeResult => {
		if(fiscalCode === "") {
			console.error("Fiscal code is blank")
			return ItalianFiscalCodeResult.INPUT_ERROR
		}
		if(fiscalCode.length != 16) {
			console.error(`Fiscal code ${fiscalCode} has wrong length. Expected 16, got ${fiscalCode.length}`)
			return ItalianFiscalCodeResult.INPUT_ERROR
		}

		const code = fiscalCode.toUpperCase()

		let validationResult = ItalianFiscalcodeValidator._validateCharacters(code, fiscalCode)
		if(validationResult) {
			return validationResult
		}

		validationResult = ItalianFiscalcodeValidator._validateLastCharacter(code, fiscalCode)
		if(validationResult) {
			return validationResult
		}

		if(data === null) {
			return ItalianFiscalCodeResult.FORMALLY_VALID
		}
		if(!data.isValid()) {
			console.error("Fiscal code input data is not valid")
			return ItalianFiscalCodeResult.INPUT_ERROR
		}

		return ItalianFiscalcodeValidator._validateData(code, fiscalCode, data)
	}

	static _validateCharacters(code: string, fiscalCode: string): ItalianFiscalCodeResult|null
	{
		for(const r of ItalianFiscalcodeValidator.CONSTANTS.CHAR_REGEXES) {
			const index: number = ItalianFiscalcodeValidator.CONSTANTS.CHAR_REGEXES.indexOf(r)
			const c = code.charAt(index) || ""
			if(!c.match(r)) {
				console.error(`Fiscal code ${fiscalCode} contains wrong characters`)
				return ItalianFiscalCodeResult.INPUT_ERROR
			}
		}

		return null
	}

	static _validateLastCharacter(code: string, fiscalCode: string): ItalianFiscalCodeResult|null
	{
		let v = Array.apply(null, Array(15))
		const sum: number = v
			.map((_, it: number) => ItalianFiscalcodeValidator._getCount(it, code.charAt(it)))
			.reduce((a: number, b: number) => a + b)

		const calculatedValue: number = sum % 26
		const lastChar = code.slice(-1)
		if(calculatedValue != (lastChar.charCodeAt(0) - "A".charCodeAt(0))) {
			console.error(`Fiscal code ${fiscalCode} last character is wrong. Expected ${String.fromCharCode(calculatedValue + "A".charCodeAt(0))}, got ${lastChar}`)
			return ItalianFiscalCodeResult.FORMALLY_NOT_VALID
		}

		return null
	}

	static _getCount(i: number, c: string)
	{
		const setChar = ItalianFiscalcodeValidator.CONSTANTS.SET_2.charAt(ItalianFiscalcodeValidator.CONSTANTS.SET_1.indexOf(c))
		if(i % 2 == 0) {
			return ItalianFiscalcodeValidator.CONSTANTS.EVEN_SET.indexOf(setChar)
		} else {
			return ItalianFiscalcodeValidator.CONSTANTS.ODD_SET.indexOf(setChar)
		}
	}

	static _validateData(code: string, fiscalCode: string, data: ItalianFiscalCodeData): ItalianFiscalCodeResult
	{
		const cfCognomeCalculated: string = ItalianFiscalcodeValidator._calcolaCFNomeCognome(data.lastName, false)
		const cfCognome: string = code.slice(0, 3).toUpperCase()
		if(cfCognomeCalculated !== cfCognome) {
			console.error(`CF ${fiscalCode}. Surname part is wrong. Expected ${cfCognomeCalculated}, got ${cfCognome}`)
			return ItalianFiscalCodeResult.NOT_VALID
		}

		const cfNomeCalculated: string = ItalianFiscalcodeValidator._calcolaCFNomeCognome(data.firstName, true)
		const cfNome = code.slice(3, 6).toUpperCase()
		if(cfNomeCalculated !== cfNome) {
			console.error(`CF ${fiscalCode}. Name part is wrong. Expected ${cfNomeCalculated}, got ${cfNome}`)
			return ItalianFiscalCodeResult.NOT_VALID
		}

		const cfDataSesso: string[] = ItalianFiscalcodeValidator._calcolaCFDataSessoCitta(code, data.birthDate, data.sex)
		const extractedDataSesso: string = code.slice(6, 15).toUpperCase()
		if(cfDataSesso.indexOf(extractedDataSesso) == -1) {
			console.error(`CF ${fiscalCode}. Date and sex part is wrong. Got ${extractedDataSesso}, Expected one of the following: `)
			console.error(cfDataSesso.join(", "))
			return ItalianFiscalCodeResult.NOT_VALID
		}

		return ItalianFiscalCodeResult.VALID
	}

	static _calcolaCFNomeCognome(s: string, isNome: boolean)
	{
		const consonants: string = s.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, "")
		const vowels: string = s.replace(/[^AEIOU]/gi, "")
		if(consonants.length == 3) {
			return consonants.toUpperCase()
		} else if(consonants.length < 3 && s.length >= 3) {
			return ItalianFiscalcodeValidator._addVowels(consonants, vowels)
		} else if(consonants.length < 3) {
			return ItalianFiscalcodeValidator._addX(consonants + vowels)
		} else if(!isNome) {
			return consonants.substring(0, 3).toUpperCase();
		}

		return consonants.toUpperCase()
	}

	static _addVowels(s: string, vowels: string): string
	{
		let i: number = 0
		let result = s
		while(result.length < 3) {
			result += vowels.charAt(i++)
		}

		return result.toUpperCase()
	}

	static _addX(s: string): string
	{
		let result = s
		while(result.length < 3) {
			result += "X"
		}

		return result.toUpperCase()
	}

	static _calcolaCFDataSessoCitta(code: string, nascita: DateTime, sex: ItalianFiscalCodeSex): string[]
	{
		const anno: number = nascita.get("year") % 100

		let giorno: number = nascita.get("day")
		if(sex == ItalianFiscalCodeSex.FEMALE) {
			giorno += 40
		}

		const mese: string = ItalianFiscalcodeValidator.CONSTANTS.MONTH_LETTERS[nascita.get("month") - 1]

		let [annoPrimaCifra, annoSecondaCifra,] = ItalianFiscalcodeValidator._numberToDigits(anno)
		let [giornoPrimaCifra, giornoSecondaCifra,] = ItalianFiscalcodeValidator._numberToDigits(giorno)

		const lettereCitta: string[] = Array.from(code.slice(11, 15))
		const citta1 = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[lettereCitta[1]]
		const citta2 = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[lettereCitta[2]]
		const citta3 = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[lettereCitta[3]]

		const giornoSecondaLettera: string = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[giornoSecondaCifra]
		const giornoPrimaLettera: string = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[giornoPrimaCifra]
		const annoSecondaLettera: string = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[annoSecondaCifra]
		const annoPrimaLettera: string = ItalianFiscalcodeValidator.CONSTANTS.DIGIT_REPLACEMENTS[annoPrimaCifra]
		return [
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaCifra}${giornoSecondaCifra}${lettereCitta[0]}${lettereCitta[1]}${lettereCitta[2]}${lettereCitta[3]}`,
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaCifra}${giornoSecondaCifra}${lettereCitta[0]}${lettereCitta[1]}${lettereCitta[2]}${citta3}`,
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaCifra}${giornoSecondaCifra}${lettereCitta[0]}${lettereCitta[1]}${citta2}${citta3}`,
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaCifra}${giornoSecondaCifra}${lettereCitta[0]}${citta1}${citta2}${citta3}`,
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaCifra}${giornoSecondaLettera}${lettereCitta[0]}${citta1}${citta2}${citta3}`,
			`${annoPrimaCifra}${annoSecondaCifra}${mese}${giornoPrimaLettera}${giornoSecondaLettera}${lettereCitta[0]}${citta1}${citta2}${citta3}`,
			`${annoPrimaCifra}${annoSecondaLettera}${mese}${giornoPrimaLettera}${giornoSecondaLettera}${lettereCitta[0]}${citta1}${citta2}${citta3}`,
			`${annoPrimaLettera}${annoSecondaLettera}${mese}${giornoPrimaLettera}${giornoSecondaLettera}${lettereCitta[0]}${citta1}${citta2}${citta3}`,
		]
	}

	static _numberToDigits(n: number): [number, number]
	{
		if(n >= 10) {
			return [Math.trunc(Math.floor(n / 10.)), n % 10]
		}

		return [0, n]
	}
}
