import React from "react"
import {
	Autocomplete,
	AutocompleteRenderInputParams,
	Box,
	TextField,
} from "@mui/material"
import { Controller } from "react-hook-form"

import {
	ErrorResponse,
	RestResponse,
	RestService,
} from "network"
import { Country } from "./country"
import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"

const ITEM_HEIGHT = 48

interface MatesCountryFieldProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	required?: boolean,
	disabled?: boolean,
	variant?: TextFieldVariant,

	showFlags: boolean,
}
interface MatesCountryFieldState
{
	loading: boolean,
	countries: [],
}
export class MatesCountryField extends React.Component<MatesCountryFieldProps, MatesCountryFieldState>
{
	static defaultProps = {
		required: false,
		disabled: false,
		variant: "standard",
		showFlags: false,
	}

	constructor(props: MatesCountryFieldProps)
	{
		super(props)
		this.state = {
			loading: false,
			countries: [],
		}
	}

	public componentDidMount()
	{
		this.setState({ loading: true })
		RestService
			.get<[]>(process.env.PUBLIC_URL + "/slim-2.json")
			.then(this.updateCountries)
			.catch(this.onError)
	}

	private updateCountries = (response: RestResponse<[], ErrorResponse>): void => {
		if(response.hasError()) {
			this.onError()
			return
		}

		this.setState({ countries: response.data! })
	}

	private onError = (): void => {
		this.setState({ countries: [] })
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<Autocomplete
						{...field}
						disablePortal
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						options={this.state.countries}
						autoHighlight
						getOptionLabel={(option) => option.name}
						ListboxProps={{ style: { maxHeight: ITEM_HEIGHT*4.5 } }}
						onChange={(_, value) => {
							field.onChange(value)
							this.props.trigger(this.props.name)
						}}
						renderOption={this.props.showFlags ? (props: any, option: Country) => (
							<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
								<img
									loading="lazy"
									width="20"
									src={`https://flagcdn.com/w20/${option["alpha-2"].toLowerCase()}.png`}
									srcSet={`https://flagcdn.com/w40/${option["alpha-2"].toLowerCase()}.png 2x`}
									alt=""
								/>
								{option.name} ({option["alpha-2"]})
							</Box>
						): undefined }
						renderInput={(params: AutocompleteRenderInputParams) => (
							<TextField
								{...params}
								fullWidth
								required={this.props.required}
								disabled={this.props.disabled || this.props.formState.isSubmitting}
								label={this.props.label}
								variant={this.props.variant}
								{...commonErrorParameters(this.props.name, this.props.formState)}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-password', // disable autocomplete and autofill
								}}
							/>
						)}
					/>
				)}
			/>
		)
	}
}
