/**
 * This class represents and handles a REST response
 */
export class RestResponse<T, E>
{
	private _data: T|E
	private _response: Response|null

	private constructor(data: any, response: Response|null)
	{
		this._data = data
		this._response = response
	}

	/**
	 * Constructs the class by parsing given response as JSON
	 *
	 * Note:
	 * - `T` is the type of the successful response
	 * - `E` is the type of the error response
	 *
	 * @param response the REST response to parse
	 * @returns a `Promise<RestResponse<T, E>>` containing either the parsed response (successful or not) or an error
	 */
	public static init<T, E>(response: Response): Promise<RestResponse<T, E>>
	{
		return response.json()
			.then((json: T|E) => new RestResponse<T, E>(json, response))
			.catch((reason: any) => RestResponse.logParseError<T, E>(reason, response))
	}

	/**
	 * Logs and handles parsing error to console
	 *
	 * Note:
	 * - `T` is the type of the successful response
	 * - `E` is the type of the error response
	 *
	 * @param reason the parse error reason
	 * @param response the original response
	 * @returns a `Promise<RestResponse<T, E>>` containing the error
	 */
	public static logParseError<T, E>(reason: any, response: Response|null = null): Promise<RestResponse<T, E>>
	{
		console.log("Cannot parse response:")
		console.log(reason)
		console.log("Response:")
		console.log(response)

		let restResponse = new RestResponse<T, E>(null, response)  // "Cannot parse response: " + reason
		return Promise.reject(restResponse)
	}

	/**
	 * Checks if response is in error by checking the http status code.
	 *
	 * @returns `true` if http status >= 400
	 */
	public hasError(): boolean
	{
		return this.status() >= 400
	}

	/**
	 * Retrieves http status code
	 *
	 * @return the http status code
	 */
	public status(): number
	{
		return this._response?.status ?? 0
	}

	/**
	 * Retrieves the parsed data as `T`
	 *
	 * - `T` is the type of the response
	 *
	 * @return an instance of `T` if the call was successful or `null` otherwise
	 */
	public get data(): T|null
	{
		if(this.hasError()) {
			return null
		}

		return (this._data as T)
	}

	/**
	 * Retrieves the original {@link Response}
	 *
	 * @returns the original {@link Response} given to this class
	 */
	public get response(): Response|null
	{
		return this._response
	}

	/**
	 * Retrieves the error response as `E`
	 *
	 * - `E` is the type of the error response
	 *
	 * @returns the error response if there was an error or `null` otherwise
	 */
	public get error(): E|null
	{
		if(!this.hasError()) {
			return null
		}

		return (this._data as E)
	}
}
