export class NumberUtils
{
	private constructor()
	{
	}

	public static toInteger(s: string, defaultValue: number): number
	{
		return parseInt(s as string, 10) || defaultValue
	}
}
