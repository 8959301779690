import React from "react"

import { TextField } from "@mui/material"
import { DateTimePicker } from "@mui/lab"
import { DateTimePickerView } from "@mui/lab/DateTimePicker/shared"

import { DateTime } from "luxon"
import { Controller } from "react-hook-form"

import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"

interface MatesDateTimePickerProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,

	minDate?: DateTime,
	maxDate?: DateTime,
	minTime?: DateTime,
	maxTime?: DateTime,
	minDateTime?: DateTime,
	maxDateTime?: DateTime,
	minutesStep?: number,
	views?: DateTimePickerView[],
	openTo?: DateTimePickerView,
	showTodayButton?: boolean,
	todayText?: React.ReactNode,
	cancelText?: React.ReactNode,
}
export class MatesDateTimePicker extends React.Component<MatesDateTimePickerProps, {}>
{
	static defaultProps = {
		disabled: false,
		required: false,
		variant: "standard",

		showTodayButton: false,
		todayText: "Today",
		cancelText: "Cancel",
		minutesStep: 1,
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<DateTimePicker
						{...field}
						mask="__/__/____ __:__:__"
						inputFormat="dd/MM/yyyy HH:mm:ss"
						ampm={false}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
						minTime={this.props.minTime}
						maxTime={this.props.maxTime}
						minDateTime={this.props.minDateTime}
						maxDateTime={this.props.maxDateTime}
						minutesStep={this.props.minutesStep}
						views={this.props.views}
						openTo={this.props.openTo}
						showTodayButton={this.props.showTodayButton}
						todayText={this.props.todayText}
						cancelText={this.props.cancelText}
						label={this.props.label}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						onChange={(value) => {
							field.onChange(value)
							this.props.trigger(this.props.name)
						}}
						renderInput={(params) =>
							<TextField
								{...field}
								{...params}
								fullWidth
								required={this.props.required}
								disabled={this.props.disabled || this.props.formState.isSubmitting}
								variant={this.props.variant}
								name={this.props.name}
								{...commonErrorParameters(this.props.name, this.props.formState)} />
						}
					/>
				)}
			/>
		)
	}
}
