import React from "react"

import { TextField } from "@mui/material"
import { TimePicker } from "@mui/lab"
import { TimePickerView } from "@mui/lab/TimePicker/shared"

import { DateTime } from "luxon"
import { Controller } from "react-hook-form"

import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"

interface MatesTimePickerProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,

	minTime?: DateTime,
	maxTime?: DateTime,
	minutesStep?: number,
	views?: TimePickerView[],
	openTo?: TimePickerView,
	cancelText?: React.ReactNode,
}
export class MatesTimePicker extends React.Component<MatesTimePickerProps, {}>
{
	static defaultProps = {
		disabled: false,
		required: false,
		variant: "standard",

		cancelText: "Cancel",
		minutesStep: 1,
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<TimePicker
						{...field}
						mask="__:__:__"
						inputFormat="HH:mm:ss"
						ampm={false}
						minTime={this.props.minTime}
						maxTime={this.props.maxTime}
						minutesStep={this.props.minutesStep}
						views={this.props.views}
						openTo={this.props.openTo}
						cancelText={this.props.cancelText}
						label={this.props.label}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						onChange={(value) => {
							field.onChange(value)
							this.props.trigger(this.props.name)
						}}
						renderInput={(params) =>
							<TextField
								{...field}
								{...params}
								fullWidth
								required={this.props.required}
								disabled={this.props.disabled || this.props.formState.isSubmitting}
								variant={this.props.variant}
								name={this.props.name}
								{...commonErrorParameters(this.props.name, this.props.formState)} />
						}
					/>
				)}
			/>
		)
	}
}
