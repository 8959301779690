import React from "react"
import {
	ErrorDialog,
	ErrorDialogOptions,
} from "modals/error-dialog"
import {
	RestResponse,
	ErrorResponse,
} from "network"
import {
	DialogContentText,
	Typography,
} from "@mui/material"

export interface TitleAndMessage
{
	title: string,
	message: string,
}

interface RestErrorDialogProps
{
	open: boolean,
	response: RestResponse<any, ErrorResponse>|null,
	showErrorCode?: boolean,
	errorParser: (code: number) => TitleAndMessage,
	options: ErrorDialogOptions,

	ok: () => void,
}

export class RestErrorDialog extends React.Component<RestErrorDialogProps, {}>
{
	static defaultProps = {
		showErrorCode: false,
		options: {
			showErrorTimestamp: false,
			timestampFormat: "",
		},
		errorParser: () => {
			return {
				title: "",
				message: "",
			}
		},
	}

	constructor(props: RestErrorDialogProps)
	{
		super(props)
	}

	public render()
	{
		return (
			<ErrorDialog open={this.props.open} ok={this.props.ok} {...this.error()} {...this.props.options}>
				{this.props.showErrorCode &&
				<DialogContentText id="alert-dialog-description">
					<Typography variant="caption">{this.getError()}</Typography>
				</DialogContentText>
				}
			</ErrorDialog>
		)
	}

	private error = (): TitleAndMessage => {
		let errorCode = this.props.response?.error?.errorCode || this.props.response?.status() || 0
		return this.props.errorParser(errorCode)
	}

	private getError = (): string => {
		let status: number = this.props.response?.status() || 0
		let error: ErrorResponse|null = this.props.response?.error || null

		let detail = ""
		if(error && error.errorCode && error.description) {
			detail = ` => ${error.errorCode} - ${error.description}`
		} else if(error && error.errorCode) {
			detail = ` => ${error.errorCode}`
		} else if(error && error.description) {
			detail = ` => ${error.description}`
		} else {
			detail = ""
		}

		return `(HTTP ${status})${detail}`
	}
}