import React from "react"
import { TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import {
	getError,
	TextFieldVariant,
} from "./fields"


interface MatesMultilineTextFieldProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,
	onChange?: (data: string) => string,

	maxLength?: number,
	maxRows?: number,
	minRows?: number,
	rows?: number,
}
interface MatesMultilineTextFieldState
{
	textLength: number,
}
export class MatesMultilineTextField extends React.Component<MatesMultilineTextFieldProps, MatesMultilineTextFieldState>
{
	static defaultProps = {
		required: false,
		variant: "standard",
		maxLength: undefined,
	}

	constructor(props: MatesMultilineTextFieldProps)
	{
		super(props)
		this.state = {
			textLength: 0,
		}
	}

	private getHelperText = (error: any): string => {
		return error?.message || `${this.state.textLength}/${this.props.maxLength}`
	}

	private updateHelperText = (value: string): void => {
		this.setState({ textLength: value.length })
	}

	private hasError = (error: any): boolean => {
		if(this.props.maxLength) {
			return Boolean(error) || this.state.textLength > this.props.maxLength!
		}

		return Boolean(error)
	}

	render()
	{
		let error = getError(this.props.formState, this.props.name)
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				defaultValue=""
				render={({ field }) => (
					<TextField
						{...field}
						fullWidth
						multiline
						minRows={this.props.minRows}
						maxRows={this.props.maxRows}
						rows={this.props.rows}
						required={this.props.required}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						label={this.props.label}
						variant={this.props.variant}
						helperText={this.getHelperText(error)}
						error={this.hasError(error)}
						onChange={({ target: { value } }) => {
							const newValue = this.props.onChange?.(value) || value
							field.onChange(newValue)
							this.updateHelperText(newValue)
							this.props.trigger(this.props.name)
						}}
					/>
				)}
			/>
		)
	}
}
