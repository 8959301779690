import { DateTime } from "luxon"

export class ItalianFiscalCodeData
{
	private _firstName: string
	private _lastName: string
	private _sex: ItalianFiscalCodeSex
	private _birthDate: DateTime

	public get firstName(): string
	{
		return this._firstName
	}

	public set firstName(value: string)
	{
		this._firstName = value
	}

	public get lastName(): string
	{
		return this._lastName
	}

	public set lastName(value: string)
	{
		this._lastName = value
	}

	public get sex(): ItalianFiscalCodeSex
	{
		return this._sex
	}

	public set sex(value: ItalianFiscalCodeSex)
	{
		this._sex = value
	}

	public get birthDate(): DateTime
	{
		return this._birthDate
	}

	public set birthDate(value: DateTime)
	{
		this._birthDate = value
	}

	public isValid = (): boolean => {
		if(this._firstName.length < 1) {
			return false
		}
		if(this._lastName.length < 1) {
			return false
		}

		return true
	}
}

export enum ItalianFiscalCodeSex
{
	MALE,
	FEMALE,
}

export enum ItalianFiscalCodeResult
{
	FORMALLY_VALID,
	VALID,
	INPUT_ERROR,
	FORMALLY_NOT_VALID,
	NOT_VALID,
}

export enum ItalianVatCodeResult
{
	FORMALLY_VALID,
	INPUT_ERROR,
	FORMALLY_NOT_VALID,
}
