import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material"
import React from "react"

interface ConfirmDialogProps
{
	open: boolean,
	title: string,
	message: string,

	okText: string,
	cancelText: string,

	ok: () => void,
	cancel: () => void,
}
export class ConfirmDialog extends React.Component<ConfirmDialogProps, {}>
{
	static defaultProps = {
		okText: "OK",
		cancelText: "Cancel",
	}

	public render()
	{
		return (
			<Dialog
				open={this.props.open}
				onClose={() => false}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.emitCancel}>{this.props.cancelText}</Button>
					<Button onClick={this.emitOk} autoFocus>{this.props.okText}</Button>
				</DialogActions>
			</Dialog>
		)
	}

	private emitOk = (): void => {
		this.props.ok()
	}

	private emitCancel = (): void => {
		this.props.cancel()
	}
}
