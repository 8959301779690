import { ItalianFiscalcodeValidator } from "./italian-fiscalcode-validator"
import { ItalianVatcodeValidator } from "./italian-vatcode-validator"

export class ValidationService
{
	private constructor()
	{
	}

	public static validateItalianFiscalCode = ItalianFiscalcodeValidator.validate

	public static validateItalianVatCode = ItalianVatcodeValidator.validate
}
