import React from "react"

import { DateTime } from "luxon"
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material"

export interface ErrorDialogOptions
{
	showErrorTimestamp?: boolean,
	timestampFormat?: string,
	showCancel?: boolean,

	okText?: string,
	cancelText?: string,
}
interface ErrorDialogProps extends ErrorDialogOptions
{
	open: boolean,
	title: string,
	message: string,

	ok: () => void,
	cancel?: () => void,
}
interface ErrorDialogState
{
	currentDateTime: string,
}
export class ErrorDialog extends React.Component<ErrorDialogProps, ErrorDialogState>
{
	static defaultProps = {
		showErrorTimestamp: false,
		timestampFormat: "dd/MM/yyyy - HH:mm:ss.SSS",
		showCancel: false,

		okText: "OK",
		cancelText: "Cancel",
	}

	constructor(props: ErrorDialogProps)
	{
		super(props)
		this.state = {
			currentDateTime: this.currentDateTime(),
		}
	}

	public render()
	{
		return (
			<Dialog
				open={this.props.open}
				fullWidth={true}
				maxWidth="sm"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
					<Container sx={{mt: 5}}>
						{this.props.children}

						{ this.props.showErrorTimestamp &&
						<DialogContentText id="alert-dialog-description">
							<Typography variant="caption">{this.state.currentDateTime}</Typography>
						</DialogContentText>
						}
					</Container>
				</DialogContent>
				<DialogActions>
					{ this.props.showCancel && <Button onClick={this.emitCancel}>{this.props.cancelText}</Button> }
					<Button onClick={this.emitOk}>{this.props.okText}</Button>
				</DialogActions>
			</Dialog>
		)
	}

	private emitOk = (): void => {
		this.props.ok()
	}

	private emitCancel = (): void => {
		this.props.cancel?.()
	}

	private currentDateTime = (): string => {
		return DateTime.now().toFormat(this.props.timestampFormat!)
	}
}