import React, { createRef } from "react"

import {
	NetworkParams,
	NetworkService,
	StringStringMap,
} from "network/services/network.service"
import { Loading } from "modals"

class BlobAndFilename
{
	public constructor(
		public blob: Blob,
		public filename: string,
		public originalResponse: Response,
	)
	{
	}
}

class DownloadError
{
	public constructor(
		public reason: any,
		public originalResponse: Response,
	)
	{
	}
}

interface AuthenticatedDownloadProps
{
	url: string,
	filename?: string,
	headers?: StringStringMap,

	onDownloadStart?: () => void,
	onSuccess?: (filename?: string) => void,
	onError?: (error: any, originalResponse: Response) => void,
	internalLoadingEnabled: boolean,
}
interface AuthenticatedDownloadState
{
	loading: boolean,
}
export class AuthenticatedDownload extends React.Component<AuthenticatedDownloadProps, AuthenticatedDownloadState>
{
	static defaultProps = {
		internalLoadingEnabled: true,
	}

	private readonly downloadRef: React.RefObject<HTMLAnchorElement> = createRef()

	constructor(props: AuthenticatedDownloadProps)
	{
		super(props)
		this.state = {
			loading: false,
		}
	}

	protected download = (): void => {
		if(this.downloadRef.current && this.downloadRef.current.href) {
			return
		}

		let params = NetworkParams.EMPTY
		if(this.props.headers) {
			params.headers = this.props.headers
		}

		this.props.onDownloadStart?.()
		this.setState({ loading: this.props.internalLoadingEnabled && true })
		NetworkService
			.get(this.props.url, params)
			.then(this.onResponseSuccess)
			.then((blob) => {
				const href = window.URL.createObjectURL(blob.blob)

				if(this.downloadRef.current) {
					this.downloadRef.current.download = this.props.filename || blob.filename
					this.downloadRef.current.href = href
					this.downloadRef.current.click()
				}
				this.setState({ loading: false })

				this.props.onSuccess?.(blob.filename)
				return Promise.resolve()
			})
			.catch((error: DownloadError) => {
				this.setState({ loading: false })
				this.props.onError?.(error.reason, error.originalResponse)
			})
	}

	private onResponseSuccess = (response: Response): Promise<BlobAndFilename> => {
		if(response.ok) {
			return response.blob()
				.then(blob => Promise.resolve(
					new BlobAndFilename(
						blob,
						this.readFilename(response),
						response,
					)
				))
				.catch(reason => {
					console.log("Cannot parse blob")
					return Promise.reject(new DownloadError(reason, response))
				})
		}

		return response.text().then(it => {
			console.log("Call failed")
			return Promise.reject(new DownloadError(it, response))
		})
	}

	private readFilename = (response: Response): string => {
		const disposition = response.headers.get("Content-Disposition")
		if(!disposition) {
			console.warn("No content-disposition header")
			return ""
		}

		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		const matches = filenameRegex.exec(disposition)
		if(matches != null && matches[1]) {
			return matches[1].replace(/['"]/g, "")
		}

		console.warn("No filename in content-disposition")
		return ""
	}

	render()
	{
		return (
			<React.Fragment>
				<Loading show={this.state.loading} />
				<a role="button" ref={this.downloadRef} onClick={this.download}>{this.props.children}</a>
			</React.Fragment>
		)
	}
}
