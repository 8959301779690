export class DateUtils
{
	public static readonly ITALIAN_DATE_FORMAT: string = "dd/MM/yyyy"

	public static readonly ITALIAN_TIME_FORMAT: string = "HH:mm"

	public static readonly ITALIAN_DATETIME_FORMAT: string = "dd/MM/yyyy HH:mm"

	public static readonly ITALIAN_DATETIME_SECONDS_FORMAT: string = "dd/MM/yyyy HH:mm:ss"

	private constructor()
	{
	}
}