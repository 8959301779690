import { ItalianVatCodeResult } from "services/validation/models"

export class ItalianVatcodeValidator
{
	private constructor()
	{
	}

	public static validate = (vatCode: string): ItalianVatCodeResult => {
		if(vatCode === "") {
			console.error("Vat code is blank")
			return ItalianVatCodeResult.INPUT_ERROR
		}
		if(vatCode.length !== 11) {
			console.error(`Vat code ${vatCode} has wrong length. Expected 11, got ${vatCode.length}`)
			return ItalianVatCodeResult.INPUT_ERROR
		}

		if(!vatCode.match(/^[0-9]+$/)) {
			console.error(`Vat code ${vatCode} contains wrong characters`)
			return ItalianVatCodeResult.INPUT_ERROR
		}

		let v = Array.apply(null, Array(11))
		const sum: number = v
			.map((_, it: number) => ItalianVatcodeValidator._getDigit(vatCode.charAt(it), it))
			.reduce((a: number, b: number) => a + b)

		if(sum % 10 !== 0) {
			return ItalianVatCodeResult.FORMALLY_NOT_VALID
		}

		return ItalianVatCodeResult.FORMALLY_VALID
	}

	private static _getDigit = (c: string, i: number) => {
		let digit = c.charCodeAt(0) - "0".charCodeAt(0)
		if(i % 2 == 0) {
			return digit
		}

		digit *= 2
		if(digit > 9) {
			digit -= 9
		}

		return digit
	}
}
