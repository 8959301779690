import React from "react"

import { TextField } from "@mui/material"
import DatePicker from "@mui/lab/DatePicker"
import { DatePickerView } from "@mui/lab/DatePicker/shared"

import { DateTime } from "luxon"
import { Controller } from "react-hook-form"

import {
	commonErrorParameters,
	TextFieldVariant,
} from "./fields"

interface MatesDatePickerProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	variant?: TextFieldVariant,

	minDate?: DateTime,
	maxDate?: DateTime,
	views?: DatePickerView[],
	openTo?: DatePickerView,
	showTodayButton?: boolean,
	todayText?: React.ReactNode,
	cancelText?: React.ReactNode,
}
export class MatesDatePicker extends React.Component<MatesDatePickerProps, {}>
{
	static defaultProps = {
		disabled: false,
		required: false,
		variant: "standard",

		showTodayButton: false,
		todayText: "Today",
		cancelText: "Cancel",
	}

	render()
	{
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<DatePicker
						{...field}
						mask="__/__/____"
						inputFormat="dd/MM/yyyy"
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
						views={this.props.views}
						openTo={this.props.openTo}
						showTodayButton={this.props.showTodayButton}
						todayText={this.props.todayText}
						cancelText={this.props.cancelText}
						label={this.props.label}
						disabled={this.props.disabled || this.props.formState.isSubmitting}
						onChange={(value) => {
							field.onChange(value)
							this.props.trigger(this.props.name)
						}}
						renderInput={(params) =>
							<TextField
								{...field}
								{...params}
								fullWidth
								required={this.props.required}
								disabled={this.props.disabled || this.props.formState.isSubmitting}
								variant={this.props.variant}
								name={this.props.name}
								{...commonErrorParameters(this.props.name, this.props.formState)} />
						}
					/>
				)}
			/>
		)
	}
}
