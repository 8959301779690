import {
	NetworkParams,
	NetworkService,
} from "network/services/network.service"
import { RestResponse } from "network/models/rest-response"
import { ErrorResponse } from "network/models/error-response"

/**
 * Service for REST network calls. It supports GET, POST, PUT and DELETE verbs with static methods.
 *
 * **WARNING**: All responses are **AUTOMATICALLY** converted and handled as JSON.
 * If the response cannot be parsed as JSON the {@link Promise}s will fail.
 * If you don't want this you should use {@link NetworkService} instead.
 *
 * Automatically handles authentication tokens, see {@link NetworkService}
 */
export class RestService
{
	private constructor()
	{
	}

	/**
	 * Executes a GET REST call.
	 *
	 * The parameter `RES` is the type of the response.
	 * If the API call or the response parse fails, the promise will also fail with an {@link ErrorResponse} response.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RestResponse<RES, ErrorResponse>>` containing the call result
	 */
	public static get<RES>(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, ErrorResponse>>
	{
		return this.genericGet<RES, ErrorResponse>(url, params, searchParams)
	}

	/**
	 * Executes a generic GET REST call.
	 *
	 * The parameter `RES` is the type of the response.
	 * The parameter `E` is the type of the error response.
	 * If the API call or the response parse fails, the promise will also fail with an `E` response.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RestResponse<RES, E>>` containing the call result
	 */
	public static genericGet<RES, E extends ErrorResponse>(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, E>>
	{
		return NetworkService
			.get(url, params, searchParams)
			.then(response => RestResponse.init<RES, E>(response))
	}

	/**
	 * Executes a POST REST call.
	 *
	 * The parameter `RES` is the type of the response
	 * If the API call or the response parse fails, the promise will also fail with an {@link ErrorResponse} response.
	 *
	 * **Warning**: Spring does not support Multipart POST requests, use {@link put} instead
	 *
	 * @param url the API URL
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RES, ErrorResponse>` containing the call result
	 */
	public static post<RES>(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, ErrorResponse>>
	{
		return this.genericPost<RES, ErrorResponse>(url, JSON.stringify(data), params, searchParams)
	}

	/**
	 * Executes a generic POST REST call.
	 *
	 * The parameter `RES` is the type of the response
	 * The parameter `E` is the type of the error response.
	 * If the API call or the response parse fails, the promise will also fail with an `E` response.
	 *
	 * **Warning**: Spring does not support Multipart POST requests, use {@link put} instead
	 *
	 * @param url the API URL
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RES, E>` containing the call result
	 */
	public static genericPost<RES, E extends ErrorResponse>(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, E>>
	{
		return NetworkService
			.post(url, data, params, searchParams)
			.then(response => RestResponse.init<RES, E>(response))
	}

	/**
	 * Executes a PUT REST call.
	 *
	 * The parameter `RES` is the type of the response
	 * If the API call or the response parse fails, the promise will also fail with an {@link ErrorResponse} response.
	 *
	 * @param url the API URL
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RES, ErrorResponse>` containing the call result
	 */
	public static put<RES>(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, ErrorResponse>>
	{
		return this.genericPut<RES, ErrorResponse>(url, JSON.stringify(data), params, searchParams)
	}

	/**
	 * Executes a generic PUT REST call.
	 *
	 * The parameter `RES` is the type of the response
	 * The parameter `E` is the type of the error response.
	 * If the API call or the response parse fails, the promise will also fail with an `E` response.
	 *
	 * @param url the API URL
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RES, E>` containing the call result
	 */
	public static genericPut<RES, E extends ErrorResponse>(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, E>>
	{
		return NetworkService
			.put(url, data, params, searchParams)
			.then(response => RestResponse.init<RES, E>(response))
	}

	/**
	 * Executes a DELETE REST call.
	 *
	 * The parameter `RES` is the type of the response
	 * If the API call or the response parse fails, the promise will also fail with an {@link ErrorResponse} response.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RES, ErrorResponse>` containing the call result
	 */
	public static delete<RES>(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, ErrorResponse>>
	{
		return this.genericDelete<RES, ErrorResponse>(url, params, searchParams)
	}

	/**
	 * Executes a generic DELETE REST call.
	 *
	 * The parameter `RES` is the type of the response.
	 * The parameter `E` is the type of the error response.
	 * If the API call or the response parse fails, the promise will also fail with an `E` response.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @returns a `Promise<RestResponse<RES, E>>` containing the call result
	 */
	public static genericDelete<RES, E extends ErrorResponse>(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<RestResponse<RES, E>>
	{
		return NetworkService
			.delete(url, params, searchParams)
			.then(response => RestResponse.init<RES, E>(response))
	}
}
