import React from "react"
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
} from "@mui/material"
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Controller } from "react-hook-form"
import { getError } from "fields/fields"

interface MatesCheckboxProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	onChange?: (data: boolean) => boolean,
	icon?: React.ReactNode,
	checkedIcon?: React.ReactNode,
}
export class MatesCheckbox extends React.Component<MatesCheckboxProps, {}>
{
	static defaultProps = {
		required: false,
		icon: (<CheckBoxOutlineBlankIcon />),
		checkedIcon: (<CheckBoxIcon />),
	}

	render()
	{
		let error = getError(this.props.formState, this.props.name)
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<FormControl
						component="fieldset"
						variant="standard"
						error={Boolean(error)}>
						<FormControlLabel
							control={
								<Checkbox
									checked={field.value}
									icon={this.props.icon}
									checkedIcon={this.props.checkedIcon}
									required={this.props.required}
									disabled={this.props.disabled || this.props.formState.isSubmitting}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										let value: boolean = event.target.checked
										const newValue = this.props.onChange?.(value) || value
										field.onChange(newValue)
										this.props.trigger(this.props.name)
									}}
								/>
							}
							label={this.props.label} />
						<FormHelperText>{error?.message}</FormHelperText>
					</FormControl>
				)}
			/>
		)
	}
}
