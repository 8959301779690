import React from "react"
import {
	Backdrop,
	CircularProgress,
} from "@mui/material"

type LoadingOptions = {
	show: boolean,

	colour?: string,
	fontSize?: string,
}

export class Loading extends React.Component<LoadingOptions, {}>
{
	static defaultProps = {
		colour: "#FFFFFF",
		fontSize: "5rem"
	}

	public render()
	{
		return (
			<Backdrop
				sx={{ color: this.props.colour, zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={this.props.show}
			>
				<CircularProgress color="inherit" size={this.props.fontSize} />
			</Backdrop>
		)
	}
}
