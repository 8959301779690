export type StringStringMap = { [id: string]: string }

/**
 * Service for generic network calls. It supports GET, POST, PUT and DELETE verbs with static methods.
 *
 * Automatically handles authentication tokens, if supplied via {@link saveAuthenticationToken}
 */
export class NetworkService
{
	private constructor()
	{
	}

	/**
	 * Executes a GET network call.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @return a {@link Promise<Response>} containing the call result
	 */
	public static get(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<Response>
	{
		return fetch(
			NetworkService.addQueryString(url, searchParams),
			NetworkService.baseInit("GET", params),
		)
	}

	/**
	 * Executes a POST network call.
	 *
	 * **Warning**: Spring does not support Multipart POST requests, use {@link put} instead
	 *
	 * @param url the API url
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @return a {@link Promise<Response>} containing the call result
	 */
	public static post(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<Response>
	{
		return fetch(
			NetworkService.addQueryString(url, searchParams),
			NetworkService.baseInitWithData("POST", data, params),
		)
	}

	/**
	 * Executes a PUT network call.
	 *
	 * @param url the API url
	 * @param data the data to be sent in the body
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @return a {@link Promise<Response>} containing the call result
	 */
	public static put(url: string, data: any, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<Response>
	{
		return fetch(
			NetworkService.addQueryString(url, searchParams),
			NetworkService.baseInitWithData("PUT", data, params),
		)
	}

	/**
	 * Executes a DELETE network call.
	 *
	 * **This verb does NOT support a request body.**
	 *
	 * @param url the API URL
	 * @param params some network parameters (content-type, cors handling, etc)
	 * @param searchParams optional query string parameters
	 *
	 * @return a {@link Promise<Response>} containing the call result
	 */
	public static delete(url: string, params: NetworkParams = NetworkParams.EMPTY, searchParams?: URLSearchParams)
		: Promise<Response>
	{
		return fetch(
			NetworkService.addQueryString(url, searchParams),
			NetworkService.baseInit("DELETE", params),
		)
	}

	private static addQueryString(url: string, searchParams?: URLSearchParams): string
	{
		if(!searchParams) {
			return url
		}

		return `${url}?${searchParams.toString()}`
	}

	private static baseInit(method: string, params: NetworkParams): object
	{
		return {
			...(NetworkService.ifExists(params.mode, "mode")),
			...(NetworkService.ifExists(params.cache, "cache")),
			method: method,
			headers: {
				...NetworkService.getHeaders(params.headers),
				...(NetworkService.ifExists(params.contentType, "Content-Type"))
			},
		}
	}

	private static ifExists(o: any, key: string): any
	{
		return o && { [key]: o }
	}

	private static baseInitWithData(method: string, data: any, params: NetworkParams): object
	{
		return {
			...NetworkService.baseInit(method, params),
			body: data,
		}
	}

	/**
	 * Appends the authentication token in specified storage (See {@link NetworkParams.DEFAULT_CONFIG})
	 * Replaces the header in storage if it already exists
	 *
	 * @param token the authentication token to be saved
	 * @param header the header name that will contain the token when calling the APIs
	 * @param template a template containing the format for the header. A `{0}` token in this template is needed to put the authentication token in place.
	 */
	public static saveAuthenticationToken = (token: string, header: string = "Authorization", template: string = "Bearer {0}"): void => {
		let authenticationToken = new AuthenticationToken()
		authenticationToken.token = token
		authenticationToken.header = header
		authenticationToken.template = template

		NetworkService.deleteAuthenticationToken(header)

		const currentTokens: AuthenticationToken[] = NetworkService.readAuthenticationTokensFromStorage()
		currentTokens.push(authenticationToken)
		NetworkService
			.getStorage()
			.setItem(NetworkParams.DEFAULT_CONFIG.storageTokenName, JSON.stringify(currentTokens))
	}

	/**
	 * Removes an authentication token, based on Header name, in specified storage (See {@link NetworkParams.DEFAULT_CONFIG})
	 *
	 * @param header the header name to search
	 */
	public static deleteAuthenticationToken = (header: string) => {
		const currentTokens: AuthenticationToken[] = NetworkService.readAuthenticationTokensFromStorage()
		const filteredTokens = currentTokens.filter((token: AuthenticationToken) => token.header !== header)
		if(!filteredTokens || filteredTokens.length === 0) {
			NetworkService
				.getStorage()
				.setItem(NetworkParams.DEFAULT_CONFIG.storageTokenName, JSON.stringify([]))
		}

		NetworkService
			.getStorage()
			.setItem(NetworkParams.DEFAULT_CONFIG.storageTokenName, JSON.stringify(filteredTokens))
	}

	/**
	 * Removes authentication token from specified storage (See {@link NetworkParams.DEFAULT_CONFIG})
	 * @deprecated Use {@link clearAuthenticationTokens}
	 */
	public static removeAuthenticationToken = (): void => {
		NetworkService.clearAuthenticationTokens()
	}

	/**
	 * Removes authentication token from specified storage (See {@link NetworkParams.DEFAULT_CONFIG})
	 */
	public static clearAuthenticationTokens = (): void => {
		NetworkService
			.getStorage()
			.removeItem(NetworkParams.DEFAULT_CONFIG.storageTokenName)
	}

	private static getStorage(): Storage
	{
		if(NetworkParams.DEFAULT_CONFIG.authenticateFromLocalStorage) {
			return localStorage
		} else {
			return sessionStorage
		}
	}

	private static getHeaders = (headers: StringStringMap): StringStringMap => {
		let token: string|null = NetworkService
			.getStorage()
			.getItem(NetworkParams.DEFAULT_CONFIG.storageTokenName)
		if(token) {
			let authenticationTokens: AuthenticationToken[] = NetworkService.readAuthenticationTokensFromStorage()
			authenticationTokens.forEach((token: AuthenticationToken) => {
				headers[token.header] = token.template.replace("{0}", token.token)
			})
		}

		return headers
	}

	private static readAuthenticationTokensFromStorage = (): AuthenticationToken[] => {
		let token: string|null = NetworkService
			.getStorage()
			.getItem(NetworkParams.DEFAULT_CONFIG.storageTokenName)
		if(!token || !(token.startsWith("[") || token.startsWith("{"))) {
			return []
		}

		if(token.startsWith("{")) {
			let authenticationToken: AuthenticationToken = JSON.parse(token) as AuthenticationToken
			return [authenticationToken]
		}

		return JSON.parse(token) as AuthenticationToken[]
	}
}

export class AuthenticationToken
{
	token: string
	header: string
	template: string
}

export class NetworkConfig
{
	/**
	 * The name of the key to be saved in the {@link Storage}, containing the authentication token
	 */
	storageTokenName: string

	/**
	 * Set this property to `true` to save the authentication token in `localStorage`
	 *
	 * If both this and {@link authenticateFromSessionStorage} are set to `true`, the token will be **ONLY** saved in `localStorage`
	 */
	authenticateFromLocalStorage: boolean

	/**
	 * Set this property to `true` to save the authentication token in `sessionStorage`
	 *
	 * If both this and {@link authenticateFromLocalStorage} are set to `true`, the token will be **ONLY** saved in `localStorage`
	 */
	authenticateFromSessionStorage: boolean
}

/**
 * This class contains some parameters for network calls and global configuration for the authentication token.
 */
export class NetworkParams
{
	/**
	 * Contains an instance of {@link NetworkParams} with default parameters:
	 * * Content-Type: `"application/json"`
	 * * mode: `undefined`
	 * * cache: `undefined`
	 * * headers: empty object
	 */
	public static readonly EMPTY: NetworkParams = new NetworkParams()

	/**
	 * Contains authentication token parameters
	 */
	public static DEFAULT_CONFIG: NetworkConfig = {
		storageTokenName: "MATES_AUTH_TOKEN",
		authenticateFromLocalStorage: true,
		authenticateFromSessionStorage: false,
	}

	contentType?: string = "application/json"
	mode?: RequestMode
	cache?: RequestCache
	headers: StringStringMap = {}
}
