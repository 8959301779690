export type TextFieldVariant = "filled" | "outlined" | "standard"

export function getError(formState: any, name: string): any
{
	if(name.indexOf(".") < 0) {
		return formState.errors[name]
	}

	let error = formState.errors
	if(!error) {
		return error
	}

	const split = name.split(".")
	for(let item of split) {
		error = error[item]
		if(!error) {
			return error
		}
	}

	return error
}

export function commonErrorParameters(
	name: string,
	formState: any,
): any
{
	let error = getError(formState, name)
	return {
		helperText: error?.message,
		error: Boolean(error),
	}
}
