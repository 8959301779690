import React from "react"
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material"
import { Controller } from "react-hook-form"
import { getError } from "fields/fields"

export interface RadioContent
{
	label: string,
	value: string,
}
interface MatesRadioProps
{
	name: string,
	label: string,
	control: any,
	trigger: any,
	formState: any,
	disabled?: boolean,
	required?: boolean,
	onChange?: (data: string) => string,
	row?: boolean,
	radios: RadioContent[],
}
export class MatesRadio extends React.Component<MatesRadioProps, {}>
{
	static defaultProps = {
		required: false,
		row: false,
	}

	render()
	{
		let error = getError(this.props.formState, this.props.name)
		return (
			<Controller
				name={this.props.name}
				control={this.props.control}
				render={({ field }) => (
					<FormControl
						component="fieldset"
						variant="standard"
						error={Boolean(error)}
						required={this.props.required}
						disabled={this.props.disabled || this.props.formState.isSubmitting}>
						<FormLabel component="legend">{this.props.label}</FormLabel>
						<RadioGroup
							{...field}
							row={this.props.row}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								let value: string = event.target.value
								const newValue = this.props.onChange?.(value) || value
								field.onChange(newValue)
								this.props.trigger(this.props.name)
							}}
						>
							{this.props.radios.map((content,) => (
								<FormControlLabel
									key={content.value}
									value={content.value}
									control={<Radio />}
									label={content.label} />
							))}
						</RadioGroup>
						<FormHelperText>{error?.message}</FormHelperText>
					</FormControl>
				)}
			/>
		)
	}
}
